.Header {
  margin-top: 20px;
  margin-bottom: 0px;
  font-weight: 500;
}

.DateWrapper {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-template-rows: repeat(2, min-content);
  grid-column-gap: 12px;
  grid-row-gap: 6px;

  margin-top: 8px;
  margin-bottom: 22px;

  span {
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;

    color: #969696;
  }
}

.DatePicker {
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  padding: 11px 8px 12px;
}
