.DetailsWrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: max-content 1fr;
  overflow-y: hidden;
  row-gap: 10px;
  overflow-y: hidden;
}

.ContentWrapper {
  height: 100%;
  overflow-y: auto;
}

.Info {
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  display: block;
}

.InputWrapper {
  margin-top: 18px;
  margin-bottom: 21px;
  span {
    display: block;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    margin-bottom: 6px;
    color: #969696;
  }
}

.Input {
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  padding: 11px 8px 12px;
  font-size: 13px;
  line-height: 15px;
  color: #2f2f34;
}

textarea.Input {
  resize: vertical;
  min-height: 100px;
  width: 98%;
  height: 38px;
}

.RadioWrapper {
  display: flex;
  flex-direction: column;
}

.RadioButton {
  display: inline-flex;
  align-items: center;
  margin-bottom: 8px;

  label {
    margin-left: 8px;
    margin-bottom: 0px;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
  }

  input[type='radio'] {
    padding: 10px;
  }
}

.Footer {
  width: 100%;
  border-top: 1px solid #dcdcdc;
  padding-top: 14px;
  display: flex;
  justify-content: center;
}

.Error {
  @extend .Info;
  color: red;
}
