.Table {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content 1fr;
  row-gap: 18px;
  overflow-y: hidden;
  height: 100%;
  max-height: 100%;
}

.Filters {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.BadgeCommon {
  border-radius: 46px;
  border: 1px solid #dcdcdc;
  color: #dcdcdc;
  padding: 9px 20px;
  background-color: transparent;
  margin-left: 8px;

  font-size: 13px;
  white-space: nowrap;
  position: relative;
  will-change: padding-left;
  transition: 0.2s padding-left ease-in, color 0.2s ease, border 0.2s ease;

  @media (max-width: 1620px) {
    padding: 8px 19px;
    margin-left: 5px;
    margin-bottom: 5px;
    font-size: 12px;
  }

  &::before {
    content: url('./checkedIcon.svg');
    position: absolute;
    top: 8px;
    left: 10px;
    width: 16px;
    height: 16px;
    opacity: 0;
    transform: scale(0);
    transition: opacity 0.2s ease-in, transform 0.2s ease-in;
  }
}

.TextInputsWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 1620px) {
    display: flex;
    flex-direction: column;
    > :nth-child(2) {
      margin-top: 5px;
    }
  }
}

.BadgeButton {
  @extend .BadgeCommon;

  &:hover {
    border-color: #2f2f34;
    color: #2f2f34;
  }
}

.BadgeButtonActive {
  @extend .BadgeCommon;
  color: #367af6;
  border-color: #367af6;
  padding-left: 30px;

  &::before {
    opacity: 1;
    transform: scale(1);
  }
}

.Inputs {
  display: grid;
  grid-template-columns: max-content 1fr;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.BadgesBox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-self: center;

  @media (max-width: 1620px) {
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: flex-start;
  }

  span {
    margin-left: 8px;
    font-weight: 500;
    font-size: 13px;

    @media (max-width: 1620px) {
      margin-left: 5px;
      font-size: 12px;
    }
  }
}

.BadgesWrapper {
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  justify-content: flex-start;

  @media (max-width: 1620px) {
    max-width: 320px;
  }
}

.SearchInput {
  position: relative;
  margin-right: 6px;
  input {
    background: #f1f1f1;
    border: none;
    border-radius: 6px;
    padding: 13px 12px 13px 32px;
    position: relative;
    outline: none;
    font-size: 13px;
    min-width: 120px;

    @media (max-width: 1620px) {
      min-width: 220px;
    }
  }

  svg {
    position: absolute;
    top: calc(50% - 9px);
    left: 12px;
    width: 16px;
    height: 16px;
  }
}
