.MainWrapper {
  width: 100%;
  height: 100%;
  max-height: 88vh;
  overflow-y: hidden;
  display: grid;
  grid-template-columns: 3fr minmax(300px, 1fr);
  grid-column-gap: 30px;
  grid-template-rows: 1fr;
}
