.Container {
  padding: 96px 26px 0px;
  max-height: 100vh;
  height: 100vh;
  overflow-y: hidden;
  max-width: minmax(100%, 1800px);
  width: 100%;
  margin: auto;
  display: grid;
  grid-template-rows: max-content 1fr;
  row-gap: 10px;
  > h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    margin: 0;
  }
}
