.Wrapper {
  width: 100%;
  border-bottom: 1px solid #dcdcdc;

  button,
  p {
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
  }

  button {
  }
}

.CommonButton {
  position: relative;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 8px 30px;
  text-align: center;
  min-width: 125px;
  transition: color 0.3s ease;

  > div {
    position: absolute;
    width: 100%;
    bottom: -1px;
    height: 2px;
    transition: opacity 0.3s ease;
    background-color: #367af6;
    left: 0px;
    opacity: 0;
  }
}

.NormalButton {
  @extend .CommonButton;
}

.ActiveButton {
  @extend .CommonButton;
  color: #367af6;

  > div {
    opacity: 1;
  }
}
