.ResultsWrapper {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  overflow: hidden;
}

.CommonTable {
  width: 100%;

  tr {
    display: grid;
    padding: 13px 16px;
    align-items: center;
  }

  th {
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #969696;
  }

  td {
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #000000;
  }

  tbody {
    tr {
      border-top: 1px solid #dcdcdc;
      padding: 16px 16px;
      background-color: transparent;
      transition: background-color 0.3s ease-in;

      &:hover {
        background-color: #f8f8f8;
      }
    }
  }
}

.NewOrdersTable {
  @extend .CommonTable;
  tr {
    grid-template-columns: 40px 3fr 1fr 1fr 2fr 1fr 1.3fr;
  }
}

.ShippingOrdersTable {
  @extend .CommonTable;

  tr {
    width: 100%;
    grid-template-rows: min-content max-content;
    grid-template-columns: 40px 3fr 1fr 1fr 2fr 1fr 1fr 1fr;
  }

  .RowActive {
    background-color: #f8f8f8;
  }
}

.MoreInfo {
  > td {
    padding: 0px 40px;
    width: 100%;
    display: block;
    grid-template-columns: none;
    grid-column: 1 / -1;
  }

  &:hover {
    background-color: #ffffff;
  }

  .NestedTable {
    width: 100%;
    tr {
      display: grid;
      grid-template-columns: 4fr 1fr 1fr 1fr 1fr;
      padding: 19px 30% 19px 40px;
    }

    thead {
      tr {
        border-top: none;
        padding: 9px 30% 9px 40px;
        pointer-events: none;
      }
    }
  }
}

.SwitchCell {
  justify-self: center;
  text-align: center;
}

.WarningCell {
  position: relative;
}

.ExpandButton {
  cursor: pointer;
  position: relative;
  width: 18px;
  height: 18px;

  &:before,
  &:after {
    content: '';
    display: block;
    background-color: #333;
    position: absolute;
    top: 50%;
    left: 0;
    transition: 0.35s;
    width: 100%;
    height: 3px;
  }

  &:before {
    transform: translatey(-50%);
  }

  &:after {
    transform: translatey(-50%) rotate(90deg);
  }
}

.ExpandButtonActive {
  @extend .ExpandButton;
  &:before {
    transform: translatey(-50%) rotate(-90deg);
    opacity: 0;
  }
  &:after {
    transform: translatey(-50%) rotate(0);
  }
}

.TableWrapper {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr max-content;
  row-gap: 16px;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.8;
  }
}

.Loader {
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background-color: #ffffff;
  animation: fade-in 0.3s ease;
  position: absolute;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;

  > h5 {
    outline: none;
  }
}

.Pagination {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  > button {
    border: none;
    outline: none;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: black;
    background-color: transparent;
    padding: 8px 10px;
    transition: color 0.4s ease;

    &:hover {
      color: #367af6;
    }

    &:active {
      color: #0a5df7;
    }

    &:disabled {
      color: rgb(172, 172, 172);
    }
  }
}

.AlertCell {
  display: flex;
  align-items: center;
  text-overflow: initial;
  > span {
    width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.AlertIcon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
