.Button {
  background-color: #367af6;
  border: none;
  border-radius: 6px;
  padding: 11px;
  min-width: 122px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: white;
  outline: none;
}
