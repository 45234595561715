.IconBase {
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
  border: none;
  border-radius: 6px;
  background-color: #f1f1f186;
  transition: background-color 0.3s ease;

  svg {
    path {
      transition: fill 0.3s ease;
    }
  }
}

.DownloadIcon {
  @extend .IconBase;

  &:hover {
    background-color: #e9fdec;

    svg {
      path {
        fill: #209531;
      }
    }
  }

  &:active {
    background-color: #d9f1dd;

    svg {
      path {
        fill: #036812;
      }
    }
  }
}

.ListIcon {
  @extend .IconBase;

  &:hover {
    background-color: #fff5e5;

    svg {
      path {
        fill: #ffbc57;
      }
    }
  }

  &:active {
    background-color: #ffedd2;

    svg {
      path {
        fill: #ffaa2c;
      }
    }
  }
}

.PreviewIcon {
  @extend .IconBase;

  &:hover {
    background-color: #f1dfff;

    svg {
      path {
        fill: #890fe8;
      }
    }
  }

  &:active {
    background-color: #dbc4ed;

    svg {
      path {
        fill: #7600d3;
      }
    }
  }
}

.GenerateIcon {
  @extend .IconBase;

  &:hover {
    background-color: #ddf3ff;

    svg {
      path {
        fill: #2a9ad6;
      }
    }
  }

  &:active {
    background-color: #c6ebff;

    svg {
      path {
        fill: #0081c6;
      }
    }
  }
}

.DeleteIcon {
  @extend .IconBase;

  &:hover {
    background-color: #ffbebe;

    svg {
      path {
        fill: #ff7070;
      }
    }
  }

  &:active {
    background-color: #ffa9a9;

    svg {
      path {
        fill: #fc5757;
      }
    }
  }
}

.WarningIcon {
  @extend .IconBase;
  background-color: transparent;
  display: inline-block;
  width: 24px;
  height: 24px;
  svg {
    path {
      fill: #ffcf76;
    }
  }

  &:hover {
    background-color: transparent;

    svg {
      path {
        fill: #ffcf76;
      }
    }
  }

  &:active {
    background-color: transparent;

    svg {
      path {
        fill: #ffcf76;
      }
    }
  }
}
