.Container {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background: #f9f9f9;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99;
  position: fixed;
  border-bottom: 1.5px solid #dcdcdc;
}

.Logout {
  display: flex;
  text-align: right;
  justify-content: flex-end;
  align-items: center;
}

.Disabled {
  height: 31px;
  width: 31px;
  border-radius: 50%;
  margin: auto;
}

.Circle {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  margin: auto;
}

.SwitchContainer {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-column-gap: 15px;
  align-items: center;

  p {
    margin-bottom: 0px;
  }
}

.ModalHeader {
  border: none;
  display: inline-flex;
  justify-content: space-between;
  padding: 30px;
}

.ModalBody {
  font-size: 13px;
  padding: 0px 30px 30px;
}

.RadioForm {
  margin-top: 20px;
  p {
    margin-bottom: 6px;
    font-weight: 500;
  }
}

.RaportContainer {
}

.LoadingContainer {
  position: relative;

  &::after {
    content: 'Ładowanie ustawień...';
    position: absolute;
    width: 0%;
    height: 100%;
    background-color: white;
    top: 0px;
    left: 0px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.4s ease;
  }
}

.LoadingContainerActive {
  @extend .LoadingContainer;

  &::after {
    width: 100%;
    visibility: initial;
    opacity: 1;
  }
}
