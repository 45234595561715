.enter {
  opacity: 0;
  transform: translateX(10%);
}

.enterActive {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 300ms, transform 300ms;
}

.exit {
  opacity: 1;
  transform: translateX(0%);
}

.exitActive {
  opacity: 0;
  transform: translateX(10%);
  transition: opacity 300ms, transform 300ms;
}
