.Container {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background: #f9f9f9;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99;
  position: fixed;
  border-bottom: 1.5px solid #dcdcdc;
}

.Logout {
  display: flex;
  text-align: right;
  justify-content: flex-end;
  align-items: center;
}

.Disabled {
  height: 31px;
  width: 31px;
  border-radius: 50%;
  margin: auto;
}

.Circle {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  margin: auto;
}

.SwitchContainer {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-column-gap: 15px;
  align-items: center;

  p {
    margin-bottom: 0px;
  }
}

.ModalHeader {
  border: none;
  display: inline-flex;
  justify-content: space-between;
  padding: 30px;
}

.ModalBody {
  font-size: 13px;
  padding: 0px 30px 30px;
}

.RadioForm {
  margin-top: 20px;
  p {
    margin-bottom: 6px;
    font-weight: 500;
  }
}

.RaportContainer {
}

.PdfModalWrapper {
  width: 90vw;
  height: 90vh;
  max-width: 90vw !important;
}

.PdfModalContent {
  height: 100%;
}
